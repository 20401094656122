<template>
  <!--Fun-Facts-Section-Start-->
  <section id="counter">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-3">
          <div class="counter text-center">
            <h3><font-awesome-icon :icon="['far', 'thumbs-up']" /> <count-to class="timer" :startVal='startVal1' :endVal='endVal1' :duration='3000'></count-to></h3>
            <h6>Happy Clients</h6>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="counter text-center">
            <h3><font-awesome-icon icon="briefcase" /> <count-to :startVal='startVal2' :endVal='endVal2' :duration='2000'></count-to></h3>
            <h6>Completed Projects</h6>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="counter text-center">
            <h3><font-awesome-icon icon="coffee" /> <count-to :startVal='startVal3' :endVal='endVal3' :duration='2000'></count-to></h3>
            <h6>Cups of Coffee</h6>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <div class="counter text-center">
<!--            <h3><font-awesome-icon icon="code" /> <span class="timer">9823686</span></h3>-->
            <h3><font-awesome-icon icon="code" /> <count-to :startVal='startVal4' :endVal='endVal4' :duration='2000'></count-to></h3>
            <h6>Lines of Code</h6>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script >
import { Options, Vue } from 'vue-class-component'
import { CountTo } from 'vue3-count-to'
import inView from 'in-view'

@Options({
  components: {
    CountTo
  },
  data () {
    return {
      startVal1: 0,
      endVal1: 0,
      startVal2: 0,
      endVal2: 0,
      startVal3: 0,
      endVal3: 0,
      startVal4: 0,
      endVal4: 0
    }
  },
  mounted () {
    // console.log('endVal', this.endVal)
    inView('#counter')
      .on('enter', () => {
        // console.log('test', this.endVal)
        if (this.endVal1 === 0) this.endVal1 = 365
        if (this.endVal2 === 0) this.endVal2 = 73987
        if (this.endVal3 === 0) this.endVal3 = 297345
        if (this.endVal4 === 0) this.endVal4 = 9823686
      })
      .on('exit', el => {
        // console.log('exit')
        // el.style.opacity = 0.5
      })
  }
})
export default class Counter extends Vue {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#counter {
  background-color: #f8f8f8;
  padding: 3% 0%;

  .counter {
    border-right: 1px solid #ddd;

    h3, h6{
      margin-top: 20px;
      margin-bottom: 10px;
    }
    h6 {
      color: #555;
      font-weight: bolder;
    }
  }
}
</style>
