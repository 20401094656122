<template>
  <section class="hero-wrap hero-wrap-2" data-parallax="scroll">
    <div class="hero-inner">
      <div class="container">
        <div class="row no-gutters slider-text js-fullheight align-items-end">
          <div class="col-md-8 mb-5 wow fadeInUp">
            <p class="breadcrumbs">
              <span class="me-2">
                <router-link to="/">Home <font-awesome-icon icon="chevron-right" /></router-link>
              </span>
              <span>product <font-awesome-icon icon="chevron-right" /></span>
            </p>
            <h1 class="mb-3 bread">Product</h1>
          </div>
        </div>
      </div>
    </div>
  </section>

  <HomeProduct/>
</template>

<script >
import { Options, Vue } from 'vue-class-component'
import HomeProduct from '@/components/home/product.vue'
import jQuery from 'jquery'

@Options({
  mounted () {
    jQuery('.hero-wrap-2').parallax({
      speed: 0.4,
      imageSrc: require('../assets/images/product/cover.jpg')
    })
  },
  components: {
    HomeProduct
  }
})
export default class Products extends Vue {}
</script>
