import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/about.vue'
import Application from '../views/application.vue'
import Introduction from '../views/introduction.vue'
import Contact from '../views/contact.vue'
import Products from '../views/products.vue'
import Product from '../views/product.vue'
import Pcm15 from '@/components/product/pcm15.vue'
import Pcm16 from '@/components/product/pcm16.vue'
import Pcm17 from '@/components/product/pcm17.vue'
import Pcm18 from '@/components/product/pcm18.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: Introduction
  },
  {
    path: '/application',
    name: 'Application',
    component: Application
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
    children: [
      {
        path: 'pcm15',
        name: 'pcm15',
        component: Pcm15
      },
      {
        path: 'pcm16',
        name: 'pcm16',
        component: Pcm16
        // props: true
      },
      {
        path: 'pcm17',
        name: 'pcm17',
        component: Pcm17
      },
      {
        path: 'pcm18',
        name: 'pcm18',
        component: Pcm18
      }
    ]
  }
  // {
  //   path: '/product/pcm15',
  //   name: 'pcm15',
  //   component: Pcm15
  // },
  // {
  //   path: '/product/pcm16',
  //   name: 'pcm16',
  //   component: Pcm16
  // },
  // {
  //   path: '/product/pcm17',
  //   name: 'pcm17',
  //   component: Pcm17
  // },
  // {
  //   path: '/product/pcm18',
  //   name: 'pcm18',
  //   component: Pcm18
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      console.log('moving to top of the page')
      window.scrollTo(0, 0)
    }
  }
})
// router.beforeEach((to, from, next) => {
//   // console.log(`Navigating to: ${to.name}`)
//   // window.scrollTo(0, 0)
//   window.scrollTo({ top: 0 })
//   next()
// })
export default router
