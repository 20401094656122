import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue3 from 'bootstrap-vue-3'
import { library } from '@fortawesome/fontawesome-svg-core'
import countTo from 'vue3-count-to'
import VueRecaptcha from 'vue3-recaptcha-v2'

import {
  faBriefcase, faChevronRight,
  faCode,
  faCoffee, faEnvelope, faFax, faFileDownload, faGlobeAsia, faIndustry, faMap,
  faMapMarker, faPaperPlane, faPhone,
  faQuoteLeft, faSeedling, faTemperatureHigh, faTemperatureLow, faThermometerEmpty, faThermometerFull,
  faUserSecret
} from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'animate.css/animate.compat.css'

library.add(faUserSecret, faThumbsUp, faBriefcase, faCoffee, faCode, faQuoteLeft, faMapMarker, faEnvelope, faPhone, faGlobeAsia, faThermometerFull, faThermometerEmpty, faTemperatureHigh, faTemperatureLow, faIndustry, faSeedling, faChevronRight, faMap, faPhone, faPaperPlane, faFileDownload, faFax)

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(VueRecaptcha, { siteKey: '6LcQUH0dAAAAAFvqVchd9qVCGqU9p__G3AR0uZqz' })
app.use(BootstrapVue3)
app.use(countTo)
app.use(store)
app.use(router)
app.mount('#app')
