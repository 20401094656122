<template>
<!--  <section class="hero-wrap hero-wrap-2 parallax-window" data-parallax="scroll" :style="{backgroundImage: 'url('+require('../assets/images/about/cover.jpg')+')'}">-->
  <section class="hero-wrap hero-wrap-2" data-parallax="scroll">
<!--    <div class="overlay"></div>-->
    <div class="hero-inner">
      <div class="container">
        <div class="row no-gutters slider-text js-fullheight align-items-end">
          <div class="col-md-8 mb-5 wow fadeInUp">
            <p class="breadcrumbs">
              <span class="me-2">
                <router-link to="/">Home <font-awesome-icon icon="chevron-right" /></router-link>
              </span>
              <span>About us <font-awesome-icon icon="chevron-right" /></span>
            </p>
            <h1 class="mb-3 bread">About Us</h1>
          </div>
        </div>
      </div>
    </div>
  </section>

  <HomeAbout msg=""/>
<!--  <Counter />-->
<!--  <section id="about">-->
<!--  </section>-->
</template>

<script >
import { Options, Vue } from 'vue-class-component'
import HomeAbout from '@/components/home/about.vue'
import Counter from '@/components/home/counter.vue'
import jQuery from 'jquery'

@Options({
  computed: {
    // parallaxSelector() {
    //   return document.querySelector(".layer")
    // }
  },
  methods: {
    // parallaxSelect() {
    //   const parallaxSelect = this.parallaxSelector
    //   console.dir('pp', parallaxSelect)
    // }
  },
  mounted () {
    jQuery('.hero-wrap-2').parallax({
      speed: 0.4,
      imageSrc: require('../assets/images/about/cover.jpg')
    })
  },

  components: {
    HomeAbout,
    Counter
  }
})
export default class About extends Vue {
}
</script>

<style lang="scss">

.hero-wrap {
  width: 100%;
  height: 850px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: 0;
  //background-attachment: fixed;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .1;
    //background: #21243d;
    //background: #000;
    //background: -moz-linear-gradient(45deg,black 0%,white 100%);
    //background: -webkit-gradient(left bottom,right top,color-stop(0%,black),color-stop(100%,white));
    //background: -webkit-linear-gradient(
    //    45deg,black 0%,white 100%);
    //background: -o-linear-gradient(45deg,black 0%,white 100%);
    //background: -ms-linear-gradient(45deg,black 0%,white 100%);
    //background: linear-gradient(
    //    45deg,black 0%,white 100%);
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff', GradientType=1 );
    //height: 850px;
  }
}

.hero-wrap.hero-wrap-2 {
  height: 600px !important;
  position: relative;

  .overlay {
    width: 100%;
    opacity: .1;
    height: 600px;
  }

  .hero-inner {
    .slider-text {
      height: 600px !important;

      > div {
        background: rgba(255, 255, 255, .7);
        margin-left: 0;
      }

      .breadcrumbs {
        font-size: 14px;
        margin-bottom: 20px;
        z-index: 99;
        text-transform: uppercase;
        font-weight: 500;

        span {
          color: rgba(0, 0, 0, .7);

          a {
            color: rgba(0, 0, 0, .7);
          }

          .svg-inline--fa {
            color: #96bb7c;
            font-size: 13px;
          }
        }
      }

      p {
        line-height: 1.5;
        //color: rgba(0,0,0,.6);
        margin-bottom: 20px;
      }

      .bread {
        font-weight: 400 !important;
        position: relative;
      }

      h1 {
        font-size: 60px;
        color: #000;
        line-height: 1.1;
        font-weight: 400;
        position: relative;
      }
    }
  }
}

#about{
}
</style>
