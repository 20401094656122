<template>

  <section class="hero-wrap hero-wrap-2" data-parallax="scroll">
    <div class="hero-inner">
      <div class="container">
        <div class="row no-gutters slider-text js-fullheight align-items-end">
          <div class="col-md-8 mb-5 wow fadeInUp">
            <p class="breadcrumbs">
              <span class="me-2">
                <router-link to="/">Home <font-awesome-icon icon="chevron-right" /></router-link>
              </span>
              <span class="me-2">
                <router-link to="/products">Products <font-awesome-icon icon="chevron-right" /></router-link>
              </span>
              <span>BIOTEC-E17<font-awesome-icon icon="chevron-right" /></span>
            </p>
            <h1 class="mb-3 bread">BIOTEC-E17 PCM</h1>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="product">
    <div class="container">
      <div class="heading wow fadeInUp">
        <h2>BIOTEC-E17 ORGANIC PCM ( n-Heptadecane )</h2>
        <h3>CAS NO: 629-78-7 EC NO: 211-108-4</h3>
        <div class="line"></div>
        <p><span><strong>B</strong></span>IOTEC-E17 is organic materials that use its latent heat, which is a physical property, to store and
          release heat at a given temperature as it changes state from solid to liquid and vice versa. This
          phase change cycle allows PCM to maintain temperature in system that PCM is applied to.
          There are wide varieties of applications for organic PCM which ranges from laboratory use to industrial use.</p>
      </div>

      <div class="heading wow fadeInUp">
        <h2>Physical and Chemical Properties</h2>
        <div class="line"></div>
        <div class="row data-table-box">
          <div class="col-md-7">
            <table class="table table-responsive">
              <tr>
                <th>Item</th>
                <th>Test Method</th>
                <th>Specification</th>
                <th>Result</th>
              </tr>
              <tr>
                <th>Appearance (at 30°C)</th>
                <td>Visual</td>
                <td>Clear Colorless Liquid</td>
                <td>Clear Colorless Liquid</td>
              </tr>
              <tr>
                <th>Density @30°C</th>
                <td>Pycnometer</td>
                <td>0.77 – 0.79 g/ml</td>
                <td>0.78 g/mL</td>
              </tr>
              <tr>
                <th>Purity</th>
                <td>GC-FID</td>
                <td>≥ 98.5 wt%</td>
                <td>≥ 99.9 wt%</td>
              </tr>
              <tr>
                <th>Melting Point</th>
                <td>ASTM E 794</td>
                <td>20.5 - 22.5 °C</td>
                <td>22.1 °C</td>
              </tr>
              <tr>
                <th>Heat of Fusion</th>
                <td>ASTM E 793</td>
                <td>≥ 200 J/g</td>
                <td>226.6</td>
              </tr>

            </table>
          </div>
          <div class="col-md-5">
            <img src="~@/assets/images/product/pcm17/dsc_analysis.jpg">
          </div>
        </div>
      </div>

      <div class="heading wow fadeInUp">
        <h2>Product Characteristics</h2>
        <div class="line"></div>
        <div class="row mt-4">
          <div class="col-md-10">
            <ul class="note-list">
              <li>Derived from Bio-Based Raw Material</li>
              <li>Long Lifetime Performance</li>
              <li>Non-Toxic and Non-Corrosive</li>
              <li>High Heat of Fusion</li>
              <li>Biodegradable</li>
              <li>Excellent Compatibility with Wide Variety of Material</li>
              <li>Chemically Stable </li>
            </ul>
          </div>
        </div>
      </div>

      <hr>
      <div class="row mt-5">
        <div class="col text-center">
          <button type="button" class="btn btn-labeled btn-danger" @click="openFile()">
            <span class="btn-label"><font-awesome-icon icon="file-download" /></span>specification in PDF
          </button>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import jQuery from 'jquery'

@Options({
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  mounted () {
    jQuery('.hero-wrap-2').parallax({
      speed: 0.4,
      imageSrc: require('../../assets/images/product/cover/3.jpg')
    })
  },
  methods: {
    openFile () {
      const route = this.$router.resolve({ path: this.publicPath + 'file/SGS_SVHC_Test_report_for_C17_n-heptadecane.pdf' })
      window.open(route.href)
    }
  }
})
export default class Pcm17 extends Vue {}
</script>
