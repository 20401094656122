<template>
  <section id="products">
<!--    <div class="row justify-content-center g-0">-->
<!--      <div class="col-md-12 heading-section text-center wow mb-5 fadeInUp ">-->
<!--        <span class="subheading">Projects</span>-->
<!--        <h2 class="mb-2">Our Unique Latest Projects</h2>-->
<!--      </div>-->
<!--    </div>-->
    <div class="col-md-8 offset-md-2">
      <div class="heading">
        <h2>P<span>roducts</span></h2>
        <div class="line"></div>
        <p><span><strong>O</strong></span>ur Unique Latest Products</p>
      </div>
    </div>
    <div class="container">
      <div class="row no-gutters">
        <div class="col-md-12 portfolio-wrap">
          <div class="row no-gutters align-items-center">
            <div class="col-md-5 img js-fullheight wow fadeInLeft" :style="{backgroundImage: 'url('+require('../../assets/images/product/home/1.jpg')+')'}">
            </div>
            <div class="col-md-7">
              <div class="text pt-5 pl-0 pl-lg-5 pl-md-4 wow fadeInUp ">
                <div class="px-4 px-lg-4">
                  <div class="desc text-md-start">
                    <div class="top">
                      <!--<span class="subheading">Date Printed {23/01/2019}</span>-->
                      <h2 class="mb-4"><router-link to="/product/pcm15">BIOTEC-V15 PCM</router-link></h2>
                    </div>
                    <div class="absolute">
                      <p>BIOTEC-V15 PCM is organic materials that use its latent heat, which is a physical property, to store and
                        release heat at a given temperature as it changes state from solid to liquid and vice versa.</p>
                      <p><router-link to="/product/pcm15" class="custom-btn">View Product</router-link></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 portfolio-wrap">
          <div class="row no-gutters align-items-center">
            <div class="col-md-5 order-md-last img js-fullheight wow fadeInLeft" :style="{backgroundImage: 'url('+require('../../assets/images/product/home/4.jpg')+')'}">
            </div>
            <div class="col-md-7">
              <div class="text pt-5 pl-0 pl-lg-5 pl-md-4 wow fadeInUp ">
                <div class="px-4 px-lg-4">
                  <div class="desc text-md-end">
                    <div class="top">
<!--                      <span class="subheading">Date Printed {23/01/2019}</span>-->
                      <h2 class="mb-4"><router-link to="/product/pcm16">BIOTEC-E16 PCM</router-link></h2>
                    </div>
                    <div class="absolute">
                      <p>BIOTEC-E16 PCM is organic materials that use its latent heat, which is a physical property, to store and
                        release heat at a given temperature as it changes state from solid to liquid and vice versa.</p>
                      <p><router-link to="/product/pcm16" class="custom-btn">View Product</router-link></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 portfolio-wrap">
          <div class="row no-gutters align-items-center">
            <div class="col-md-5 img js-fullheight  wow fadeInRight" :style="{backgroundImage: 'url('+require('../../assets/images/product/home/5.jpg')+')'}">
            </div>
            <div class="col-md-7">
              <div class="text pt-5 pr-md-5 wow fadeInUp ">
                <div class="px-4 px-lg-4">
                  <div class="desc text-md-start">
                    <div class="top">
<!--                      <span class="subheading">Date Printed {23/01/2019}</span>-->
                      <h2 class="mb-4"><router-link to="/product/pcm17">BIOTEC-E17 PCM</router-link></h2>
                    </div>
                    <div class="absolute">
                      <p>BIOTEC-E17 PCM is organic materials that use its latent heat, which is a physical property, to store and
                        release heat at a given temperature as it changes state from solid to liquid and vice versa.</p>
                      <p><router-link to="/product/pcm17" class="custom-btn">View Product</router-link></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 portfolio-wrap">
          <div class="row no-gutters align-items-center">
            <div class="col-md-5 order-md-last img js-fullheight wow fadeInLeft" :style="{backgroundImage: 'url('+require('../../assets/images/product/home/2.jpg')+')'}">
            </div>
            <div class="col-md-7">
              <div class="text pt-5 pl-0 pl-lg-5 pl-md-4 wow fadeInUp ">
                <div class="px-4 px-lg-4">
                  <div class="desc text-md-end">
                    <div class="top">
<!--                      <span class="subheading">Date Printed {23/01/2019}</span>-->
                      <h2 class="mb-4"><router-link to="/product/pcm18">BIOTEC-E18 PCM</router-link></h2>
                    </div>
                    <div class="absolute">
                      <p>BIOTEC-E18 PCM is organic materials that use its latent heat, which is a physical property, to store and
                        release heat at a given temperature as it changes state from solid to liquid and vice versa.</p>
                      <p><router-link to="/product/pcm18" class="custom-btn">View Product</router-link></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class HomeProduct extends Vue {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

//*, *::before, *::after {
//  -webkit-box-sizing: border-box;
//  box-sizing: border-box;
//}

section#products{
  padding: 6em 0;
  position: relative;
  text-align: center;

  .heading-section {

    .subheading {
      display: inline-block;
      margin-bottom: 0;
      color: #96bb7c;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      position: relative;

      &:before {
        position: absolute;
        top: 50%;
        left: -60px;
        content: '';
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 50px;
        height: 1px;
        margin-top: -1px;
        background: #96bb7c;
      }

      &:after {
        position: absolute;
        top: 50%;
        right: -60px;
        content: '';
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 50px;
        height: 1px;
        margin-top: -1 px;
        background: #96bb7c;
      }
    }

    h2 {
      font-size: 60px;
      font-weight: 400;
      line-height: 1.5;
      color: rgba(0, 0, 0, 0.8);
    }
  }
  .heading{
    .line {
      border: 1px solid #96bb7c;
      width: 15%;
    }
    p {
      padding: 20px 0px;
      font-size: 13px;
      font-weight: bolder;
      color: #555;
    }
  }

  .portfolio-wrap {
    display: block;
    width: 100%;
    margin-bottom: 7em;

    .img, .blog-img, .user-img {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .text {
      position: relative;

      .subheading {
        display: block;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 3px;
        color: #000000;
        font-weight: 700;
      }

      .desc {
        position: relative;
        width: 100%;

        .top {
          position: relative;

          &:after {
            position: absolute;
            top: -30px;
            left: -150px;
            content: '';
            width: 150px;
            height: 1px;
            background: #000000;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }

          h2{
            font-size: 60px;
            display: inline-block;
            line-height: 1.3;

            a, router-link {
              color: #000000;
              padding-bottom: 5px;
            }
          }
        }

        &:hover .top:after {
          width: 200px;
        }

        p {
          margin-bottom: 30px;

          .custom-btn {
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 3px;
            color: #96bb7c;
            font-weight: 700;
          }
        }
      }
    }

    .desc.text-md-end{
      .top:after {
        right: -150px;
        margin: 0 0 0 auto;
      }
    }
  }
}

@media (max-width: 767.98px){
  .portfolio-wrap {
    .text {
      .top{
        &:after {
          display: none;
        }
      }
    }
  }
}

</style>
