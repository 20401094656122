<template>
  <section class="hero-wrap hero-wrap-2" data-parallax="scroll">
    <!--    <div class="overlay"></div>-->
    <div class="hero-inner">
      <div class="container">
        <div class="row no-gutters slider-text js-fullheight align-items-end">
          <div class="col-md-8 mb-5 wow fadeInUp">
            <p class="breadcrumbs">
              <span class="me-2">
                <router-link to="/">Home <font-awesome-icon icon="chevron-right" /></router-link>
              </span>
              <span>contact <font-awesome-icon icon="chevron-right" /></span>
            </p>
            <h1 class="mb-3 bread">Contact</h1>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script >
import { Options, Vue } from 'vue-class-component'
import jQuery from 'jquery'

@Options({
  methods: {},
  mounted () {
    jQuery('.hero-wrap-2').parallax({
      speed: 0.4,
      imageSrc: require('../assets/images/contact/cover.jpg')
    })
  },
  components: {}
})
export default class Contact extends Vue {
}
</script>

<style lang="scss">
#contact {

  .form-box h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .contact-info {

    .icon{
      margin-bottom: 10px;

      .svg-inline--fa {
        font-size: 20px;
        color: #96bb7c;
      }
    }

    p {
      span {
        display: block;
        font-size: 18px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 7px;
      }

      a {
        color: #1a1a1a;
        text-decoration: none;
        background-color: transparent;
      }
    }
  }
}

@media (min-width: 992px){
  #contact {
    .contact-info .border-height {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
