<template>

  <section class="hero-wrap hero-wrap-2" data-parallax="scroll">
    <!--    <div class="overlay"></div>-->
    <div class="hero-inner">
      <div class="container">
        <div class="row no-gutters slider-text js-fullheight align-items-end">
          <div class="col-md-8 mb-5 wow fadeInUp">
            <p class="breadcrumbs">
              <span class="me-2">
                <router-link to="/">Home <font-awesome-icon icon="chevron-right" /></router-link>
              </span>
              <span>Application <font-awesome-icon icon="chevron-right" /></span>
            </p>
            <h1 class="mb-3 bread">Product Application</h1>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="application">
    <div class="container">
      <div class="col-md-10 col-md-offset-2">
        <div class="heading">
          <h2>Product Applica<span>tion</span></h2>
          <div class="line"></div>
<!--          <p><span><strong>P</strong></span>hase change material(PCM) is normally further process into slumy or powder forms by way of Marco Encapsulation and micro encapsulation(MPCM). It has a wide range of usage and appliction:-</p>-->
        </div>
      </div>

      <br>
      <div class="wow fadeInUp description">
<!--        <p class="mt-3 inden"><span><strong>P</strong></span>hase Change Material (PCM) is a substance which release/absorb sufficient-->
<!--          energy at phase transition to provide useful heat/cooling by melting and solidifying at the phase change temperature (PCT), a PCM is capable of storing and releasing large amount of energy compared to sensible heat storage.-->
<!--        </p>-->
        <p class="mt-3 inden">
          <span><strong>P</strong></span>hase Change Material (PCM) is normally further process into slurry and powder forms by way of marco encpsulation and micro encapsulation(MPCM). It has a wide range of usage and application:-
        </p>
      </div>

      <br>
      <div class="row">
        <div class="col-md-6 col-xs-12 wow slideInUp" data-wow-duration="1s">
          <ul class="text-start">
            <li>Building Materials</li>
            <li>Shipping and Packaging</li>
            <li>Transportation</li>
            <li>Textile</li>
            <li>HVAC</li>
            <li>Thermal Energy Storage</li>
            <li>Automobile</li>
            <li>Catering</li>
            <li>Terecomumnication</li>
            <li>Electronic</li>
            <li>Temperature Peak Stabilization</li>
            <li>Cold Chain</li>
<!--            <li><strong>Biotec-V15</strong> : Cold chain, Packaging</li>-->
<!--            <li><strong>Biotec-E16</strong> : carparts, semiconductors</li>-->
<!--            <li><strong>Biotec-E17</strong> : Housing and building</li>-->
<!--            <li><strong>Biotec-E18</strong> : Housing, building, textile</li>-->
          </ul>
        </div>
      </div>

    </div>
  </section>
</template>

<script >
import { Options, Vue } from 'vue-class-component'
import jQuery from 'jquery'

@Options({
  data () {
    return {
      selected: '',
      teams: [
        {
          name: '',
          description: ''
        }
      ]
    }
  },
  methods: {},
  mounted () {
    jQuery('.hero-wrap-2').parallax({
      speed: 0.4,
      imageSrc: require('../assets/images/application/cover.jpg')
    })
  },
  components: {}
})
export default class Application extends Vue {
}
</script>

<style lang="scss">
#application {
  text-align:center;
  padding:2% 0%;

  .description{
    text-align: left;
    p{
      font-size: 16px;
      font-weight: normal;
    }
  }

  ul {
    list-style: none; /* Remove default bullets */

    li::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #96bb7c; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  }
}
</style>
