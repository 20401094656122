<template>
  <section id="slider">

<!--      <carousel id="home-carousel" :autoplay="4000" :transition="2000" :wrap-around="true">-->
<!--        <slide v-for="slide in 5" :key="slide">-->
<!--          <div class="item" :style="{backgroundImage: 'url('+require('../assets/images/Slider/1.jpg')+')'}">-->
<!--            <div class="carousel-caption container"></div>-->
<!--          </div>-->
<!--        </slide>-->
<!--      </carousel>-->

    <carousel id="home-carousel" :pauseAutoplayOnHover="true" :autoplay="4000" :transition="2000" :wrap-around="true" v-if="true">
      <slide v-for="(data, index) in slides" :key="index">
        <div class="item" :style="inlineBgImage(data.img)">
          <div class="carousel-caption container">
            <div class="row">
              <div class="col col-md-7 col-sm-12 col-xs-12">
                <h1>{{ data.title  }}</h1>
                <h2>{{ data.title2  }}</h2>
                <p>{{ data.desp  }}</p>
              </div>
            </div>
          </div>
        </div>
      </slide>

      <template #addons>
        <navigation />
      </template>
    </carousel>
  </section>

  <HomeAbout msg=""/>
  <Product />
<!--  <Counter />-->
  <div style="background-color: rgba(144, 183, 117, 0.3);padding: 2% 0%;"> </div>
  <Team />
</template>

<script >
import { Options, Vue } from 'vue-class-component'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import HomeAbout from '@/components/home/about.vue' // @ is an alias to /src
import HomeProduct from '@/components/home/product.vue'
import Counter from '@/components/home/counter.vue'
import Application from '@/components/home/application.vue'

@Options({
  // props: {
  //   time_copyright: String
  // },
  data () {
    return {
      slides: [
        {
          title: 'Advanced bio',
          title2: 'Materials for smart energy',
          desp: 'Phase change material ( PCM ) is substance with a  high heat of fusion which when melted and solidified at certain temperature, is capable of storing and releasing large amount of energy.',
          img: '/images/Slider/1.jpg'
        },
        {
          title: 'Advanced bio',
          title2: 'Materials for smart energy',
          desp: 'Phase change material ( PCM ) is substance with a  high heat of fusion which when melted and solidified at certain temperature, is capable of storing and releasing large amount of energy.',
          img: '/images/Slider/3.jpg'
        },
        {
          title: 'Advanced bio',
          title2: 'Materials for smart energy',
          desp: 'Phase change material ( PCM ) is substance with a  high heat of fusion which when melted and solidified at certain temperature, is capable of storing and releasing large amount of energy.',
          img: '/images/Slider/5.jpg'
        },
        {
          title: 'Advanced bio',
          title2: 'Materials for smart energy',
          desp: 'Phase change material ( PCM ) is substance with a  high heat of fusion which when melted and solidified at certain temperature, is capable of storing and releasing large amount of energy.',
          img: '/images/Slider/1.jpg'
        },
        {
          title: 'Advanced bio',
          title2: 'Materials for smart energy',
          desp: 'Phase change material ( PCM ) is substance with a  high heat of fusion which when melted and solidified at certain temperature, is capable of storing and releasing large amount of energy.',
          img: '/images/Slider/3.jpg'
        },
        {
          title: 'Advanced bio',
          title2: 'Materials for smart energy',
          desp: 'Phase change material ( PCM ) is substance with a  high heat of fusion which when melted and solidified at certain temperature, is capable of storing and releasing large amount of energy.',
          img: '/images/Slider/5.jpg'
        }
      ]
    }
  },
  mounted () {
    // new wow.WOW().init()
    /* ==============================================
    Preloader
   =============================================== */
    // (function () {
    //   $(window).load( function() {
    //     $('#pre-status').fadeOut()
    //     $('#preloader').delay(350).fadeOut('slow')
    //   })
    // }())

    this.timeCopyright = new Date().getFullYear()
    // new Date().getFullYear()
  },
  methods: {
    inlineBgImage (src) {
      const bgImage = require('@/assets' + src)

      return {
        backgroundImage: `url("${bgImage}")`
      }
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    HomeAbout,
    Product: HomeProduct,
    Counter,
    Team: Application
  }
})
export default class Home extends Vue {
  // timeCopyright = '';
  // timeCopyright = new Date().getFullYear();
  // console.log(new Date().getFullYear());
}
</script>

<style lang="scss">
/*Slider-stylesheets-include-here*/

#home-carousel {

  overflow: hidden;

  .carousel__slide {
    /* Carousel animation */
    h1, h2, p {
      opacity:0;
      -moz-transform: scale(0.5);
      -webkit-transform: scale(0.5);
      -o-transform: scale(0.5);
      -ms-transform: scale(0.5);
      transform: scale(0.5);
    }
    h1 {
      -webkit-transition: all 0.5s ease-in-out 0.15s;
      -moz-transition: all 0.5s ease-in-out 0.15s;
      -ms-transition: all 0.5s ease-in-out 0.15s;
      -o-transition: all 0.5s ease-in-out 0.15s;
      transition: all 0.5s ease-in-out 0.15s;
    }
    &.carousel__slide--active h1, &.carousel__slide--active h2, &.carousel__slide--active p {
      opacity:1;
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
    h2 {
      -webkit-transition: all 0.5s ease-in-out 0.30s;
      -moz-transition: all 0.5s ease-in-out 0.30s;
      -ms-transition: all 0.5s ease-in-out 0.30s;
      -o-transition: all 0.5s ease-in-out 0.30s;
      transition: all 0.5s ease-in-out 0.30s;
    }
    p {
      -webkit-transition: all 0.5s ease-in-out 0.45s;
      -moz-transition: all 0.5s ease-in-out 0.45s;
      -ms-transition: all 0.5s ease-in-out 0.45s;
      -o-transition: all 0.5s ease-in-out 0.45s;
      transition: all 0.5s ease-in-out 0.45s;
    }
  }

  .item {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 650px;

    &:before {
      background-color: #000;
      content: "";
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.5;
      position: absolute;
      width: 100%;
    }
  }

  .carousel-caption {
    left: 15px;
    right: 15px;
    top: 50%;
    font-size: 15px;
    text-align: left;
    color: #fff;
    text-shadow: none;
    margin-top: -130px;

    h1 {
      font-size:35px;
      margin-bottom:10px;
      letter-spacing:2px;
      font-weight:100;
    }

    h2 {
      color:#fff;
      font-size:75px;
      font-weight:600;
      margin-top:0px;
      text-transform:none;
      letter-spacing:2px;
      margin-bottom:20px;
    }

    p {
      color:#FFFFFF;
      font-size:17px;
      font-weight:normal;
      line-height:27px;
    }

    .col{
      margin: 0;
    }
  }

  .carousel__prev,
  .carousel__next {
    box-sizing: content-box;
    border-radius: 0;
    background-color: #96bb7c;
    color:#fff;
    font-size: 32px;
    height: 40px;
    line-height: 40px;
    margin-top: -20px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 45px;
    z-index: 999;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -o-transition: 300ms;
    -ms-transition: 300ms;
    transition: 300ms;
    //transform: translate(-50%,-50%);
  }

  .carousel__prev {
    left:-32px;
  }
  .carousel__next {
    right:-32px;
  }
  &:hover .carousel__prev {
    left:20px;
  }
  &:hover .carousel__next {
    right:20px;
  }
}

</style>
