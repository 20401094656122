<template>
<!--  <section class="hero-wrap hero-wrap-2" data-parallax="scroll">-->
<!--    <div class="hero-inner">-->
<!--      <div class="container">-->
<!--        <div class="row no-gutters slider-text js-fullheight align-items-end">-->
<!--          <div class="col-md-8 mb-5 wow fadeInUp">-->
<!--            <p class="breadcrumbs">-->
<!--              <span class="me-2">-->
<!--                <router-link to="/">Home <font-awesome-icon icon="chevron-right" /></router-link>-->
<!--              </span>-->
<!--              <span class="me-2">-->
<!--                <router-link to="/products">Products <font-awesome-icon icon="chevron-right" /></router-link>-->
<!--              </span>-->
<!--              <span>{{ $route.name }}<font-awesome-icon icon="chevron-right" /></span>-->
<!--            </p>-->
<!--            <h1 class="mb-3 bread">{{ $route.name }}</h1>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
  <router-view/>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
// import jQuery from 'jquery'
// global.jQuery = jQuery

@Options({
  mounted () {
    // var params = this.$route.params
    // this.url_data = this.$route.params.id
    // console.log('pid', this.$route.name)

    // var img_cover;
    // switch (this.$route.name) {
    //   case 'pcm15' : this.img_cover = require('../assets/images/product/cover/1.jpg'); break
    //   case 'pcm16' : this.img_cover = require('../assets/images/product/cover/2.jpg'); break
    //   case 'pcm17' : this.img_cover = require('../assets/images/product/cover/3.jpg'); break
    //   case 'pcm18' : this.img_cover = require('../assets/images/product/cover/4.jpg'); break
    // }

    // jQuery('.hero-wrap-2').parallax({
    //   speed: 0.4,
    //   imageSrc: this.img_cover
    // })
  }
})
export default class Product extends Vue {
  // console.log(this.$route.params);
}
</script>

<style lang="scss">
section#product{

  padding:5% 0%;

  .heading {
    text-align:left;
    margin-bottom: 50px;

    h2 {
      font-family: 'helvetica_ce_55_romanregular';
      color: #1a1a1a;
    }
    .line{
      margin: 0;
    }
    p {
      padding:20px 0px;
      font-size:16px;
      font-weight: normal;
      color:#555;
    }
  }

  .data-table-box{
    //padding:20px 0px;
    //font-size:13px;
    margin-top: 20px;

    .table{
      tr{
        border: 0px;
        td{
          padding: 10px 0;
        }
      }
    }

    img{
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
