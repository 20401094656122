<template>
  <header id="menu">

    <nav class="navbar navbar-expand-lg fixed-top navbar-default navbar-dark" :class="{'on': navbgShow}">

        <div class="container">
          <div class="navbar-brand d-xl-none text-black">
            <a href="#">
              <img src="@/assets/images/logo_nav_desktop.png">
              ENERCON E-TEC CO.,LTD.
            </a>
          </div>
          <div class="navbar-brand navbar-brand-desktop d-none d-xl-block">
            <div class="img-box" :class="{ 'img-min': logomin }">
              <div class="img-bg">
                <img src="@/assets/images/logo_nav_desktop.png">
              </div>
            </div>
            <div class="text">ENERCON E-TEC CO.,LTD.</div>
          </div>

          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" v-on:click="showNavBg">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link to="/" class="nav-link">Home</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/about" class="nav-link">About</router-link>
              </li>
              <li class="nav-item dropdown">
                <router-link to="/products" class="nav-link">Product</router-link>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><router-link to="/product/pcm15" class="nav-link">BIOTEC-V15 PCM</router-link></li>
                  <li><router-link to="/product/pcm16" class="nav-link">BIOTEC-E16 PCM</router-link></li>
                  <li><router-link to="/product/pcm17" class="nav-link">BIOTEC-E17 PCM</router-link></li>
                  <li><router-link to="/product/pcm18" class="nav-link">BIOTEC-E18 PCM</router-link></li>
                </ul>
              </li>
              <li class="nav-item">
                <router-link to="/introduction" class="nav-link">Introduction</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/application" class="nav-link">Application</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/contact" class="nav-link">Contact</router-link>
              </li>
            </ul>
          </div>
        </div>
    </nav>

  </header>
  <router-view/>
  <!--Contact-Section-Start-->
  <section id="contact" class="bg-light">
    <div class="container">
      <div class="col-md-8 offset-md-2">
        <div class="heading">
          <h2>CONTACT <span>US</span></h2>
          <div class="line"></div>
          <p><span><strong>T</strong></span>hank you for giving us an opportunity to assist you. We shall contact you as soon as possible upon receiving your enquiry.
            Should you require to contact us otherwise, our contact details are appended below.</p>
        </div>
      </div>
      <div class="text-center row">
        <div class="col-md-6 col-sm-6 contact-sec-1">
          <h4>CONTACT IN<span>FO</span></h4>
          <ul class="contact-form wow fadeInUp">
            <li>
              <font-awesome-icon icon="briefcase" class="fa" />
              <h6><strong>Address:</strong> 17/2 Soi Namyen Tambon Nernpra
                Amphur Muang Rayong 21150 Thailand
              </h6>
            </li>
            <li>
              <font-awesome-icon icon="envelope" class="fa" />
              <h6><strong>Mail Us:</strong> <a href="mailto:jameslee@enercon.com.sg">jameslee@enercon.com.sg</a></h6>
            </li>
            <li>
              <font-awesome-icon icon="phone" class="fa" />
              <h6><strong>Phone: </strong><a href="tel:+6638947188">+66 38 947 188 , 064-138-5156 </a></h6>
            </li>
            <li>
              <font-awesome-icon icon="fax" class="fa" />
              <h6><strong>Fax: </strong><a href="fax:+6638947187">+66 38 947 187 </a></h6>
            </li>
            <li>
              <font-awesome-icon icon="globe-asia" class="fa" />
              <h6><strong>Website:</strong> <a href="http://enercon-etec-pcm.com/">www.enercon-etec-pcm.com</a></h6>
            </li>
          </ul>
        </div>
        <div class="col-md-6 col-sm-6">
          <form id="contact-form" name="contact-form" ref="form" @submit.prevent="sendEmail">
            <div class="row wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms">
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="text" id="from_name" name="from_name" class="form-control" placeholder="Name" required="required">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="email" id="email" name="email" class="form-control" placeholder="Email Address" required="required">
                </div>
              </div>
            </div>
            <div class="form-group wow fadeInDown">
              <input type="text" id="subject" name="subject" class="form-control" placeholder="Subject" required="required" autocomplete="off">
            </div>
            <div class="form-group wow fadeInDown">
              <textarea name="message" id="message" class="form-control" rows="4" placeholder="Enter your message" required="required"></textarea>
            </div>
<!--            <a class="btn-send btn btn-primary col-md-12 col-sm-12 col-xs-12" >Send Now</a>-->
<!--            <input class="btn-send btn btn-primary col-md-12 col-sm-12 col-xs-12" type="submit" value="Send Now">-->
            <div class="mb-3">
              <vue-recaptcha
                theme="light"
                size="normal"
                :tabindex="0"
                @widgetId="recaptchaWidget = $event"
                @verify="capchaCallbackVerify($event)"
                @expired="capchaCallbackExpired()"
                @fail="capchaCallbackFail()"
              />
            </div>
            <!-- <button class="btn-send btn btn-primary col-md-12 col-sm-12 col-xs-12" type="submit" form="contact-form" value="Submit" :disabled="form_loading || !capchaCheck"> -->
            <button class="btn-send btn btn-primary col-md-12 col-sm-12 col-xs-12" type="submit" form="contact-form" value="Submit" :disabled="form_loading">
              Send
              <div class="spinner-border spinner-border-sm text-light" role="status" v-if="form_loading">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>

  <footer>
    <h6>
      Copyright © {{ timeCopyright }} All rights reserved
      <!--        Copyright © {{ timeCopyright }} All rights reserved | This template is made with <i class="fa fa-heart" aria-hidden="true"></i> by <a href="https://dee-soft.com" target="_blank">DeeSoft</a>-->
    </h6>
  </footer>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import { WOW } from 'wowjs'
import emailjs from 'emailjs-com'
import { VueRecaptcha, useRecaptcha } from 'vue3-recaptcha-v2'
import jQuery from 'jquery'
global.jQuery = jQuery
require('@/assets/js/parallax')
// require('nodemailer')

// // Configuración de handlebars
// const hbsConfig = {
//   viewEngine: {
//     extName: '.hbs',
//     partialsDir: path.join(__dirname, '../views/'),
//     layoutsDir: path.join(__dirname, '../views/'),
//     defaultLayout: ''
//   },
//   viewPath: path.join(__dirname, '../views/'),
//   extName: '.hbs'
// }
//
// // Configuración transportador NodeMailer
// const transporter = nodemailer.createTransport({
//   service: 'gmail',
//   auth: { user: process.env.USER, pass: process.env.PASSWORD }
// })

@Options({

  data () {
    return {
      navbgShow: true,
      timeCopyright: '',
      scrollPosition: 0,
      logomin: false,
      form_loading: false,
      email_name: '',
      email_email: '',
      email_subject: '',
      email_message: '',
      capchaCheck: false
    }
  },
  mounted () {
    // var t
    // function fullheight () {
    //   console.log('fullh')
    //   t.css('height', jQuery(window).height())
    // }
    // window.onload = function () {
    //   t = jQuery('.js-fullheight')
    //   fullheight()
    // }
    // window.onresize = function () {
    //   fullheight()
    // }
    // jQuery(function(){
    //   jQuery('.dropdown').hover(function() {
    //       jQuery(this).addClass('open');
    //     },
    //     function() {
    //       jQuery(this).removeClass('open');
    //     });
    // });
    window.addEventListener('scroll', this.handleScroll)

    const d = new Date()
    this.timeCopyright = d.getFullYear()

    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: false
    })
    wow.init()
  },
  methods: {
    showNavBg: function (event) {
      // if(this.navbgShow == false) this.navbgShow = true
      this.navbgShow = true
    },
    handleScroll (event) {
      this.scrollPosition = window.scrollY
      if (this.scrollPosition > 20) {
        this.logomin = true
      } else {
        this.logomin = false
      }
    },
    sendEmail () {
      try {
        this.form_loading = true
        emailjs.sendForm('service_6zgnatq', 'template_qikm1o8', this.$refs.form, 'user_jkyx7PzO7tBhSREnC0Nbo')
          .then((result) => {
            console.log('SUCCESS!', result.text)
            this.form_loading = false
            this.capchaCheck = false
            this.$refs.form.reset()
          }, (error) => {
            console.log('FAILED...', error.text)
            this.form_loading = false
          })
      } catch (error) {
        console.log({ error })
        this.form_loading = false
      }
    },
    capchaCallbackVerify (response) {
      // console.log('capcha',response)
      this.capchaCheck = true
    },
    capchaCallbackExpired () {
      console.log('expired!')
      this.capchaCheck = false
    },
    capchaCallbackFail () {
      console.log('fail')
      this.capchaCheck = false
    }
  },
  components: {
    VueRecaptcha
  },
  setup: () => {
    // Reset Recaptcha
    // const { resetRecaptcha } = useRecaptcha()
    // const recaptchaWidget = ref(null)
    //
    // const callbackVerify = (response) => {
    //   console.log(response)
    //   this.capchaCheck = true
    // }
    // const callbackExpired = () => {
    //   console.log("expired!")
    //   this.capchaCheck = false
    // }
    // const callbackFail = () => {
    //   console.log("fail")
    // }
    // // Reset Recaptcha action
    // const actionReset = () => {
    //   this.capchaCheck = false
    //   resetRecaptcha(recaptchaWidget.value)
    // }
    //
    // return {
    //   recaptchaWidget,
    //   callbackVerify,
    //   callbackFail,
    //   actionReset
    // }
  }
})
export default class app extends Vue {}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #2c3e50;
}

/*Navigation-stylesheets-include-here*/
#menu {
  position: absolute;

  nav {
    //z-index: 1030;
    &.navbar-default {
      background: rgba(248, 248, 248, 0);
      border: rgba(248, 248, 248, 0);
      padding: 5px 5px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, .10);

      &.on {
        //background: rgba(0, 0, 0, 0.8);
        background: #FFFFFF;
        transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -webkit-transition: all 0.3s;
      }

      .navbar-brand{
        margin: 0;
        a{
          color: #333;
          text-decoration: none;
          img{
            height: 50px;
          }
        }

        &.navbar-brand-desktop{
          height: 30px;

          div{
            float: left;
            color: #444;
            font-weight: bold;
          }
          div.img-box{
            display: block;
            width: 210px;
            height: 1px;
            position: relative;
            transition: width 0.5s;

            .img-bg {
              position: absolute;
              background: #FFFFFF;
              padding: 5px;
              border-radius: 50%;
              top: -20px;
              img {
                height: 200px;
                transition: height 0.5s;
              }
            }

            &.img-min{
              width: 70px;
              .img-bg {
                img{
                  height: 50px;
                }
              }
            }
          }
          div.text{
            color: #006900;
            font-size: 24px;
          }
        }
      }

      .navbar-toggler{
        background: #96bb7c;
      }

      ul.navbar-nav {
        left: 25%;
        position: relative;

        li{
          //font-size: 15px;
          padding: 15px;
          a{
            color: #000;
          }
          a.active, a.router-link-active{
            color: #96bb7c;
          }

          .dropdown-menu{
            width: 230px;
            li:hover{
              a{
                color: #96bb7c;
              }
            }
          }
        }
      }

      //ul.navbar-nav > li > a {
      //  background: none;
      //  color: #96bb7c;
      //}
    }

  }
}

#contact{
  text-align: center;
  padding: 3% 0% 3% 2%;

  .heading{
    .line {
      border: 1px solid #96bb7c;
      width: 15%;
    }
    p {
      padding: 20px 0px;
      font-size: 13px;
      font-weight: bolder;
      color: #555;
    }
  }

  .contact-sec-1 {
    text-align: left;

    ul {
      padding: 0;
      position: relative;
      top: 20px;

      li {
        display: block;
        padding-bottom: 30px;

        .fa {
          color: #666;
          float: left;
          margin-right: 10px;
        }

        h6 {
          font-size: 14px;
          color: #666;
          margin: 0;

          a {
            color: #666;
            text-decoration: none;
            &:hover {
              color: #96bb7c;
              -webkit-transition-duration: 0.3s;
              -moz-transition-duration: 0.3s;
              -ms-transition-duration: 0.3s;
              -o-transition-duration: 0.3s;
              transition-duration: 0.3s;
            }
          }
        }
      }
    }
  }

  .form-group {
    margin-bottom: 30px;
  }
}

footer{
  //margin-top: 30px;
  padding: 20px 0;
  background: #96bb7c;
  h6{
    text-align: center;
    margin: 0;
    a{
      color: #FFFFFF;
    }
  }
}
</style>
