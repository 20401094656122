<template>

  <section class="hero-wrap hero-wrap-2" data-parallax="scroll">
    <!--    <div class="overlay"></div>-->
    <div class="hero-inner">
      <div class="container">
        <div class="row no-gutters slider-text js-fullheight align-items-end">
          <div class="col-md-8 mb-5 wow fadeInUp">
            <p class="breadcrumbs">
              <span class="me-2">
                <router-link to="/">Home <font-awesome-icon icon="chevron-right" /></router-link>
              </span>
              <span>Introduction <font-awesome-icon icon="chevron-right" /></span>
            </p>
            <h1 class="mb-3 bread">Product Introduction</h1>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="introduction">
    <div class="container">
      <div class="col-md-10 col-md-offset-2">
        <div class="heading">
          <h2>Product Introduc<span>tion</span></h2>
          <div class="line"></div>
          <!--          <p><span><strong>P</strong></span>hase change material(PCM) is normally further process into slumy or powder forms by way of Marco Encapsulation and micro encapsulation(MPCM). It has a wide range of usage and appliction:-</p>-->
        </div>
      </div>

      <br>
      <div class="wow fadeInUp description">
        <p class="mt-3 inden"><span><strong>P</strong></span>hase Change Material (PCM) is a substance which release/absorb sufficient
          energy at phase transition to provide useful heat/cooling by melting and solidifying at the phase change temperature (PCT), a PCM is capable of storing and releasing large amount of energy compared to sensible heat storage.
        </p>
        <p class="mt-3">
          Heat is absorb or released when the material changed from solid to liquid and vice versa or when the internal structure of the material changes, PCM are accordingly referred to as latent heat storage (LHS) Material.
        </p>
        <p class="mt-3">Enercon Biotec PCM is a bio based material derived from by-product of crude palm oil (CPO) through physical refining process. Palm Fatty Acid Distillate (PFAD) are extracted and reproduced into our phase change material through hydrocarbon generation process. The processing plant is located in Thailand   with a production capacity of approx. 10,000 tons per anmum. The plant produce Biotec - V 15 (n-Pentadecane), Biotec - E 16 (n-Hexadecane), Biotec - E 17 (n-Heptadecane) and BioTec - E 18 (n-Octadecane).</p>
      </div>
    </div>

    <br>
    <div class="row">
      <div class="text-start col-md-6 col-xs-12 wow slideInUp" data-wow-duration="1s">
<!--        <h3>Below PCM Applocation</h3>-->
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6 col-xs-12 wow slideInUp" data-wow-duration="1s">
        <div class="template-wrap">

          <div class="ep-flowchart" id="ep-flowchart">
            <div class="ep-svg-wrap">

              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 650">
                <g id="Page-1" fill="none" fill-rule="evenodd">
                  <g id="Course-Template">
                    <g id="arrows" transform="translate(163 98)">

                      <g id="arrows/arrow-1" transform="rotate(90 139 134)">
                        <g id="arrow-1">
                          <path id="Path-1" stroke="#89969F" d="M67 3H38.009C32.48 3 28 7.48 28 13v247c0 5.523 4.483 10 10.009 10H67"/>
                          <path id="Path-2" stroke="#89969F" d="M28.5 137H.996"/>
                          <polygon id="Triangle-2-Copy-2" fill="#89969F" points="67 6 67 0 70 3"/>
                          <polygon id="Triangle-2-Copy-4" fill="#89969F" points="67 273 67 267 70 270"/>
                        </g>
                      </g>

                      <g id="arrows/arrow-2.1" transform="translate(9 125) rotate(90 0 0)">
                        <g id="arrow-2.1">
                          <path stroke="#89969F" stroke-linecap="square" d="M.336 2.5h38.46"/>
                          <polygon fill="#89969F" points="37 5 37 0 40 2.5"/>
                        </g>
                      </g>
                      <g id="arrows/arrow-2.2-copy" transform="translate(275 125) rotate(90 0 0)">
                        <g id="arrow-2.2">
                          <path stroke="#89969F" stroke-linecap="square" d="M.336 2.5h38.46"/>
                          <polygon  fill="#89969F" points="37 5 37 0 40 2.5"/>
                        </g>
                      </g>

                      <g id="arrows/arrow-3" transform="translate(0 290) rotate(270)">
                        <g id="arrow-3">
                          <path stroke="#89969F" d="M67 3H38.009C32.48 3 28 7.48 28 13v247c0 5.523 4.483 10 10.009 10H67"/>
                          <path stroke="#89969F" d="M28.5 137H.996"/>
                          <polygon fill="#89969F" points="0 140 0 134 -5 137"/>
                        </g>
                      </g>

                      <g transform="translate(140 330) rotate(90)">
                        <path stroke="#89969F" stroke-linecap="square" d="M.336 2.5h38.46"/>
                        <polygon fill="#89969F" points="37 5 37 0 40 2.5"/>
                      </g>

                      <g transform="translate(140 405) rotate(90)">
                        <path stroke="#89969F" stroke-linecap="square" d="M.336 2.5h38.46"/>
                        <polygon fill="#89969F" points="37 5 37 0 40 2.5"/>
                      </g>
                    </g>

                    <!-- box-->
                    <g id="ep-content" class="ep-svg-block" transform="translate(200 36)">
                      <rect id="Rectangle" width="200" height="60" fill="#8BE5AD" rx="20"/>
                      <text id="Content" fill="#586075" font-family="Roboto-Regular, Roboto" font-size="18" letter-spacing="1">
                        <tspan x="16" y="25">crude palm oil</tspan>
                        <tspan x="70" y="50">(cpo)</tspan>
                      </text>
                    </g>

                    <g id="ep-elements" class="ep-svg-block" transform="translate(66 165)">
                      <rect id="Rectangle" width="200" height="60" fill="#8BE5AD" rx="20"/>
                      <text id="Elements-of-Design" fill="#586075" font-family="Roboto-Regular, Roboto" font-size="18" letter-spacing="1">
                        <tspan x="55" y="25">physical</tspan>
                        <tspan x="50" y="49">refinning</tspan>
                      </text>
                    </g>
                    <g id="ep-principles" class="ep-svg-block" transform="translate(335 165)">
                      <rect id="Rectangle" width="200" height="60" fill="#8BE5AD" rx="20"/>
                      <text id="Principles-of-Organi" fill="#586075" font-family="Roboto-Regular, Roboto" font-size="18" letter-spacing="1">
                        <tspan x="50" y="25">chemical</tspan>
                        <tspan x="50" y="49">refinning</tspan>
                      </text>
                    </g>

                    <g id="ep-elements" class="ep-svg-block" transform="translate(66 265)">
                      <rect id="Rectangle" width="200" height="60" fill="#8BE5AD" rx="20"/>
                      <text id="Elements-of-Design" fill="#586075" font-family="Roboto-Regular, Roboto" font-size="18" letter-spacing="1">
                        <tspan x="10" y="25">palm fattty acid</tspan>
                        <tspan x="43" y="49">distillate</tspan>
                      </text>
                    </g>
                    <g id="ep-principles" class="ep-svg-block" transform="translate(335 265)">
                      <rect id="Rectangle" width="200" height="60" fill="#8BE5AD" rx="20"/>
                      <text id="Principles-of-Organi" fill="#586075" font-family="Roboto-Regular, Roboto" font-size="18" letter-spacing="1">
                        <tspan x="20" y="25">high free fatty</tspan>
                        <tspan x="60" y="49">acid oil</tspan>
                      </text>
                    </g>

                    <g id="ep-unity" class="ep-svg-block" transform="translate(177 393)">
                      <rect id="Rectangle" width="250" height="40" fill="#8BE5AD" rx="20"/>
                      <text id="Content" fill="#586075" font-family="Roboto-Regular, Roboto" font-size="18" letter-spacing="1">
                        <tspan x="19" y="25">pcm manufacturing</tspan>
                      </text>
                    </g>

                    <g id="ep-unity" class="ep-svg-block" transform="translate(172 468)">
                      <rect id="Rectangle" width="263" height="40" fill="#8BE5AD" rx="20"/>
                      <text id="Content" fill="#586075" font-family="Roboto-Regular, Roboto" font-size="18" letter-spacing="1">
                        <tspan x="19" y="25">encapsulation(mpcm)</tspan>
                      </text>
                    </g>

                    <g id="ep-unity" class="ep-svg-block" transform="translate(223 544)">
                      <rect id="Rectangle" width="150" height="40" fill="#8BE5AD" rx="20"/>
                      <text id="Content" fill="#586075" font-family="Roboto-Regular, Roboto" font-size="18" letter-spacing="1">
                        <tspan x="22" y="25">end users</tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script >
import { Options, Vue } from 'vue-class-component'
import jQuery from 'jquery'

@Options({
  methods: {},
  mounted () {
    jQuery('.hero-wrap-2').parallax({
      speed: 0.4,
      imageSrc: require('../assets/images/introduction/cover.jpg')
    })
  },
  components: {}
})
export default class Introduction extends Vue {
}
</script>

<style lang="scss">
#introduction {
  text-align: center;
  padding: 2% 0%;

  .description {
    text-align: left;

    p {
      font-size: 16px;
      font-weight: normal;
    }
  }
}

$c-primary: #586075;
$c-secondary: #8BE5AD;
$c-accent: #F5F5F;

.template-wrap {
  width: 600px;
  height: 100%;
  //border: 1px solid #767676;
  margin: 0 auto;
  padding: .5em;
  figure {
    margin: 1em auto;
  }
}
// default
.ep-flowchart {
  width: 100%;
  position: relative;
  background: white;
}
.ep-svg-wrap {
  .ep-svg-block {
    //cursor: pointer;

    rect, text {
      transition: all .3s ease;
      text-transform: uppercase;
    }

    //&:hover rect, &:hover text {
    //  transform: translateY(-5px);
    //  text-shadow: 0 1px 0 #fff, 0 -1px 0 #000;
    //}
  }
}
</style>
